._3rBQq {
  margin-bottom: 60px;
}
._3rBQq:last-child {
  margin-bottom: 0;
}
@media (max-width: 567px) {
  ._3rBQq {
    margin-bottom: 40px;
  }
}
._1dqvh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
@media (max-width: 567px) {
  ._1dqvh {
    flex-wrap: wrap;
  }
}
._26Kel {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 567px) {
  ._26Kel {
    justify-content: center;
    width: 100%;
    text-align: center;
  }
}
._3Rtwa {
  color: #afb599;
}
._3Rtwa b {
  margin-right: 12px;
  color: #474b2f;
}
@media (max-width: 567px) {
  ._3Rtwa {
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    text-align: center;
  }
}
