.oHIuu {
  padding: 0 129px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.oHIuu > div {
  max-width: 1108px;
  padding-bottom: 60px;
}
.oHIuu > div:last-child {
  padding-bottom: 0;
}
@media (max-width: 1365px) {
  .oHIuu {
    padding: 0 120px;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .oHIuu {
    padding: 0 40px;
  }
  .oHIuu > div {
    padding-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .oHIuu {
    padding: 0 24px;
  }
}
