._2MjO1 {
  width: 100%;
  min-width: 64px;
  height: 100%;
  min-height: 64px;
  position: relative;
}
._2MjO1:before {
  content: '';
  z-index: 100;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  width: 101% !important;
  min-width: 40px;
  height: 101% !important;
  min-height: 64px;
  background-color: #f4f4f1 !important;
  background-image: url("/static/images/preloader/preloader.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  transform: translate(-50%, -50%);
}
